<template>
  <div id="emo-drop-warp" class="emo-drop-warp"></div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'emojiContent',
    props: {
      handleModelV2: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        likeEmo: require('@/assets/images/share-content/like-emo.png'),
        xinEmo: require('@/assets/images/share-content/xin-emo.png'),
        flowerEmo: require('@/assets/images/share-content/flower-emo.png'),
        goodEmo: require('@/assets/images/share-content/good-emo.png'),
        thumbEmo: require('@/assets/images/share-content/dianzan-emo.png'),
        toRightAni: ['emoOneAni 3s 1 forwards', 'emoThreeAni 3s 1 forwards'],
        toLeftAni: ['emoFourAni 3s 1 forwards', 'emoTwoAni 3s 1 forwards'],
        thumbUpClickNum: 0,
      };
    },
    computed: {
      ...mapGetters('share/contentLanding', ['MusicContentAb', 'PageTitle']),
    },
    mounted() {
      this.emoDropWarp = document.getElementById('emo-drop-warp');
      this.dividingLine = Math.floor(this.emoDropWarp.offsetWidth / 2);
      this.maxLeft = this.dividingLine + 100;
      this.minLeft = this.dividingLine - 100;
      this.emoArr = [this.likeEmo, this.xinEmo, this.flowerEmo, this.goodEmo];
    },
    methods: {
      createEmo(emoUrl) {
        return new Promise((resolve) => {
          try {
            setTimeout(() => {
              let left = this.randomNum(this.maxLeft, this.minLeft, true);

              let emo = document.createElement('img');

              this.emoDropWarp.appendChild(emo);

              if (this.dividingLine > left) {
                emo.style.animation = this.toLeftAni[this.randomNum(1, 0)];
              } else {
                emo.style.animation = this.toRightAni[this.randomNum(1, 0)];
              }

              emo.style.left = `${left}px`;
              emo.src = emoUrl;
              emo.className = 'img';

              emo.addEventListener('animationend', () => {
                setTimeout(() => {
                  this.emoDropWarp.removeChild(emo);
                }, 500);
              });
              resolve();
            }, 300);
          } catch (e) {
            console.log(e);
          }
        });
      },
      async thumbUpClick(emoUrl, remarks) {
        let currentUrl;

        if (this.MusicContentAb === 'c' || this.MusicContentAb === 'd') {
          this.$track({ page_title: this.PageTitle, element_name: '详情页曝光', remarks });
          this.thumbUpClickNum++;

          currentUrl = this.emoArr[this.randomNum(3, 0)];
        } else {
          this.$track({ page_title: this.PageTitle, element_name: '互动', remarks });
          if (emoUrl) {
            this.thumbUpClickNum++;
            currentUrl = emoUrl;
          } else if (this.thumbUpClickNum === 0) {
            this.thumbUpClickNum++;
            currentUrl = this.thumbEmo;
          } else {
            this.thumbUpClickNum++;

            currentUrl = this.emoArr[this.randomNum(3, 0)];
          }
        }

        // 连续点击三次拉起下载
        if (this.thumbUpClickNum === 6) {
          this.thumbUpClickNum = 0;
          if (this.MusicContentAb === 'd') {
            this.handleModelV2(true);
          } else {
            document.getElementById('auto-download').click();
          }
        }
        for (let i = 0; i < 3; i++) {
          await this.createEmo(currentUrl);
        }
      },
      randomNum(max, min, float) {
        return float ? Math.random() * (max - min) + min : Math.floor(Math.random() * (max - min + 1));
      },
    },
  };
</script>

<style lang="less">
  .emo-drop-warp {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .img {
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      left: 5rem;
      z-index: 4;
    }
  }
  @keyframes emoOneAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(80deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(0.5rem, 6rem) rotate(138deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(1rem, 9.4rem) rotate(180deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(1.2rem, 8rem) rotate(226deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(1.5rem, 9.4rem) rotate(280deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(1.7rem, 8.8rem) rotate(313deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(1.9rem, 9.4rem) rotate(360deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(2rem, 9.2rem) rotate(400deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(2.1rem, 9.4rem) rotate(430deg);
      animation-timing-function: ease-out;
    }
  }
  @keyframes emoTwoAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(-94deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(-0.5rem, 6rem) rotate(-178deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(-0.8rem, 9.4rem) rotate(-200deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(-1rem, 7.3rem) rotate(-246deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(-1.3rem, 9.4rem) rotate(-280deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(-1.6rem, 8.5rem) rotate(-323deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(-1.8rem, 9.4rem) rotate(-370deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(-1.9rem, 9rem) rotate(-400deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(-2rem, 9.4rem) rotate(-430deg);
      animation-timing-function: ease-out;
    }
  }
  @keyframes emoThreeAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(30deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(0.5rem, 6.5rem) rotate(88deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(0.8rem, 9.4rem) rotate(130deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(1rem, 8.5rem) rotate(180deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(1.3rem, 9.4rem) rotate(230deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(1.6rem, 8.9rem) rotate(293deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(1.8rem, 9.4rem) rotate(350deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(1.9rem, 9.2rem) rotate(390deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(2rem, 9.4rem) rotate(450deg);
      animation-timing-function: ease-out;
    }
  }
  @keyframes emoFourAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(-84deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(-0.5rem, 6.5rem) rotate(-128deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(-0.6rem, 9.4rem) rotate(-180deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(-1rem, 7.3rem) rotate(-226deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(-1.3rem, 9.4rem) rotate(-270deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(-1.6rem, 8rem) rotate(-303deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(-1.8rem, 9.4rem) rotate(-340deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(-1.9rem, 9.2rem) rotate(-370deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(-2rem, 9.4rem) rotate(-420deg);
      animation-timing-function: ease-out;
    }
  }
</style>
